<template>
	<v-dialog v-model="editMode" persistent max-width="600px">
		<template #activator="{ on }">
			<div class="text-center" style="width: 100%">
				<v-btn text small rounded color="warning" v-on="on">
					{{ $t('cv.addNewFem') }}
				</v-btn>
			</div>
		</template>
		<v-card rounded="xl">
			<v-card-title>
				<span class="headline">{{ $t('cv.addNewSkill') }}</span>
				<v-spacer />
				<v-btn icon @click="switchEditMode()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text class="pb-0">
				<v-row class="mb-2">
					<v-col>
						<v-chip
							:ripple="false"
							v-for="skill in newSkills"
							:key="`skill${skill.id}`"
							class="mx-1 my-1"
							color="green darken-1"
							close
							@click:close="callRemoveSkill(skill)"
						>
							{{ skill.name }}
						</v-chip>
					</v-col>
				</v-row>
				<v-autocomplete
					v-model="selectedSkill"
					:items="selectableSkills"
					:loading="loading || loadingParent"
					:search-input.sync="search"
					:filter="customFilter"
					hide-details="auto"
					hide-selected
					item-text="name"
					item-value="id"
					:label="`${$t('search.search')}...`"
					return-object
					outlined
					rounded
					class="pb-3"
				>
					<template #no-data>
						<v-list-item v-if="search == null">
							<v-list-item-title>
								<strong>{{ $t('search.search') }}</strong>
							</v-list-item-title>
						</v-list-item>
						<v-list-item v-else-if="adquiredSkill(search)">
							<v-list-item-title>{{ $t('cv.alreadyAdded') }}</v-list-item-title>
						</v-list-item>
						<v-list-item v-else @click.stop="callAddSkillNew(search)">
							<v-list-item-content>
								<v-list-item-title>
									{{ $t('settings.add') }} <strong>{{ search }}</strong>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<template #item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.name" @click.stop="callAddSkill(item)" />
						</v-list-item-content>
					</template>
				</v-autocomplete>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// generic accent removal from input string, add any missing characters
function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function(letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function(match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	name: 'CVSkillsAdd',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			editMode: false,
			search: null,
			selectedSkill: null,
			newSkills: []
		}
	},
	computed: {
		selectableSkills() {
			return this.availableSkills.filter((skill) => !this.skills.map((s) => s.id).includes(skill.id))
		},
		...mapGetters({
			availableSkills: 'cv/availableSkills',
			skills: 'cv/skills',
			user: 'users/user'
		})
	},
	watch: {
		search(val) {
			if (val) this.queryAvailableSkills(val)
		}
	},
	created() {
		this.queryAvailableSkills('')
	},
	methods: {
		switchEditMode() {
			this.editMode = !this.editMode
		},
		queryAvailableSkills(search) {
			this.loading = true
			this.fetchAvailableSkills({ keywords: search }).then(() => {
				this.loading = false
			})
		},
		customFilter(item, queryText) {
			const text = removeAccents(item.name.toLowerCase())
			const searchText = removeAccents(queryText.toLowerCase())
			return text.includes(searchText)
		},
		adquiredSkill(search) {
			return this.skills.map((skill) => skill.name).includes(search)
		},
		callAddSkill(skill) {
			this.loading = true
			this.addSkill({
				userID: this.user.id,
				skillID: skill.id
			})
				.then(({ success, skill }) => {
					if (success) {
						this.newSkills.push(skill)
						this.selectedSkill = null
						this.search = ''
					}
				})
				.then(() => {
					this.loading = false
				})
		},
		callAddSkillNew(search) {
			this.loading = true
			this.addSkillNew({
				userID: this.user.id,
				search
			})
				.then(({ success, skill }) => {
					if (success) {
						this.newSkills.push(skill)
						this.selectedSkill = null
						this.search = ''
					}
				})
				.then(() => {
					this.loading = false
				})
		},
		callRemoveSkill(skill) {
			this.loading = true
			this.removeSkill({
				userID: this.user.id,
				skillID: skill.id
			}).then(() => {
				this.loading = false
				this.newSkills.splice(skill, 1)
			})
		},
		...mapActions('cv', ['fetchAvailableSkills', 'addSkill', 'removeSkill', 'addSkillNew'])
	}
}
</script>
